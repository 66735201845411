<template>
  <div class="schedules-details-header">
    <div class="back-to-schedules">
      <div
        class="flex items-center cursor-pointer"
        tabindex="0"
        role="button"
        @click="handleBackToSchedules"
      >
        <img
          src="@/assets/images/arrow-left.svg"
          alt="go back to schedules page"
          class="back-to-schedules__icon"
        />
        <span class="back-to-schedules__text">
          back
        </span>
      </div>
    </div>
    <div class="folder-name">
      <img
        src="@/assets/images/my_combinations_results.svg"
        alt="Schedules page title icon"
        class="folder-name__icon"
      />
      <h4 class="folder-name__text">{{ scheduleName }}</h4>
    </div>
  </div>
</template>

<script>
  import { SCHEDULES_ROUTE } from '@/router/routeNames'
  import router from '@/router'

  export default {
    name: 'SchedulesDetailsHeader',
    props: {
      scheduleName: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup() {
      const handleBackToSchedules = () => {
        router.push({ name: SCHEDULES_ROUTE.name })
      }
      return { handleBackToSchedules }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-header {
    background-color: $mi-white;
    padding: 8px 24px 0;

    .back-to-schedules {
      display: flex;
      align-items: center;
      gap: 8px;

      &__icon {
        cursor: pointer;
        width: 18px;
        height: 14.4px;
      }

      &__text {
        color: $mi-anthracite-800;
        font-size: $mi-field-font-size;
        line-height: 150%;
        padding-left: 8px;
        text-transform: uppercase;
      }
    }

    .folder-name {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 16px 0;

      &__text {
        font-weight: 400;
        margin: 0;
      }

      &__icon {
        width: 32px;
        height: 32px;
      }
    }
  }
</style>
